import api from "../../utils/api";

export const getAll = (product_id) => async (dispatch) => {
  try {
    const response = await api.get(`/videos/${product_id}`);
    dispatch({
      type: "VID_RECORDS",
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const store = (data) => async (dispatch) => {
  dispatch({
    type: 'VIDEO_BUTTON'
  })
  try {
    const response = await api.post(`/videos`, data);
    if (response.data.status === 409 || response.data.status === 404) {
			dispatch({
				type: 'VID_ERROR',
				payload: response.data.title
			})
    } else {
      dispatch({
        type: "VID_STORE",
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: 'VID_ERROR',
      payload: error.response.data.errors.video_id[0]
    })
  }
};

export const destroy = (id, product_id) => async (dispatch) => {
  try {
    await api.delete(
      `/videos/${product_id}/${id}`
    );
    dispatch({
      type: "VID_DELETE",
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

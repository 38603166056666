import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../redux/actions/recipeActions";
import { Link, useRouteMatch } from "react-router-dom";
import MBreadcrumb from "../components/Breadcrumbs";

export default function Show() {
  const dispatch = useDispatch();
  let { url } = useRouteMatch();
  const rr_rec = useSelector((state) => state.recipeReducers);

  useEffect(() => {
    if (!rr_rec.loaded) dispatch(getAll());
  }, [dispatch, rr_rec.loaded]);

	useEffect( () => {
		dispatch({
			type: 'REC_REDIRECT_OFF'
		})
	},[dispatch])

  return (
    <div className="animate__animated animate_fadeIn animate__faster">
      <MBreadcrumb list={["Home", "Recipes", "Show All"]} />
      <div className="flex justify-end mt-2 w-11/12 m-auto">
        <button className="btnHead mr-1" onClick={ () => dispatch({ type:'REC_RELOAD' })}>
          <i className="fa fa-sync mr-2"></i> Refresh
        </button>
        <Link className="btnHead" to={`${url}/insert`}>
          <i className="fa fa-plus"></i> Add Recipe
        </Link>
      </div>
      <table className="tableDefault">
        <thead>
          <tr>
            <th>Id</th>
            <th>Recipe</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rr_rec.loaded ? (
            rr_rec.records && rr_rec.records.length > 0 ? (
              rr_rec.records.map((item, key) => (
                <tr key={key}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Link className="btnEdit" to={`${url}/${item.id}/props`}>
                      <i className="fa fa-edit"></i>
                    </Link>
                    <Link className="btnDelete" to={`${url}/${item.id}/delete`}>
                      <i className="fa fa-trash"></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="norecords">
                  No Records
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={4} className="loading">
                <i className="fa fa-spin fa-spinner fa-lg"></i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

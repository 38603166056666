import { FormControl, FormLabel, Radio } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getPayment, getPaymentHistory } from "../../redux/actions/paymentActions";


export default function History() {
  const dispatch = useDispatch();
  const pay_red = useSelector((state) => state.paymentReducers);
  const [data, setData] = useState(undefined);
  const [payments, setPayments] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (!pay_red.payment_loaded) {
        dispatch(getPayment());
    }
    if (!pay_red.payment_history_loaded) {
        dispatch(getPaymentHistory());
    }
  });

  useEffect(() => {
    if (pay_red.payment_loaded) {  
      setData(pay_red.payment);
    }
  }, [pay_red.payment_loaded, pay_red.payment]);
  useEffect(() => {
    if (pay_red.history) {  
      setPayments(pay_red.history);
    }
  }, [pay_red.payment_history_loaded, pay_red.history]);

  return (
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="flex-auto p-6 font-semibold justify-center text-center">
            Billing History
        </h1>
        {((data !== undefined) && (payments !== undefined) ) ? (
            <div className="flex-col">
                { (payments && payments.length) ?
                payments.map((payment, i) => {
                    return (
                        <div key={i} className="block w-2/5 mx-auto my-5 py-3 border rounded-md border-gray-300">
                            <h2 className="block font-semibold">#{payment.id}</h2>
                            <div className="px-10"></div>
                        </div>
                    )
                }) : (
                    <div className="text-center">
                        No Records Found
                    </div>
                )}
            </div>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
  );
}

const initialState = {
	loading: false,
	button: false,
	records: [],
	loaded: false,
	redirect: false,
	errors: {},
	success: false,
	content: {
		loaded: false,
		records: []
	}
}

export default function categoryReducers(state = initialState, action) {
	switch(action.type) {
		case 'REC_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'REC_STORE': {
			return {
				...state,
				records: (state.records === undefined) ? [action.payload] : state.records.concat(action.payload),
				redirect: true,
				button: false,
				errors: {}
			}
		}
		case 'REC_UPDATE': {
			// eslint-disable-next-line
			let indice = state.records.findIndex( item => item.id === action.payload.id );
			const copy_array = [...state.records];
			copy_array[indice] = action.payload
			return {
				...state,
				records: copy_array,
				button: false,
				success: true
			}
		}
		case 'REC_DESTROY': {
			// eslint-disable-next-line
			const arreglo = state.records.filter( item => item.id !== action.payload );
			return {
				...state,
				records: arreglo,
				button: false,
				redirect: true
			}
		}
		case 'REC_RELOAD': {
			return {
				...state,
				loaded: false
			}
		}
		case 'REC_BUTTON': {
			return  {
				...state,
				button: true
			}
		}
		case 'REC_REDIRECT_OFF': {
			return {
				...state,
				redirect: false,
				errors: {}
			}
		}
		case 'REC_ERROR': {
			return {
				...state,
				button: false,
				errors: action.payload
			}
		}
		case 'REC_SUCCESS_OFF': {
			return {
				...state,
				success: false
			}
		}
		case 'REC_SUCCESS': {
			return {
				...state,
				success: true
			}
		}
		case 'REC_CONTENT': {
			return {
				...state,
				content: {
					loaded: true,
					records: action.payload
				}
			}
		}
		case 'REC_CONTENT_RESTART': {
			return {
				...state,
				content: {
					loaded: false,
					records: []
				}
			}
		}
		default: return state;
	}
}
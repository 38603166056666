import React from "react";
import { Link } from "react-router-dom";
import { verifyLogin } from "../../utils/function";
import { useDispatch } from "react-redux";
import { logoutAction } from '../../redux/actions/mainActions'

export default function Menu() {
  const isLogged = verifyLogin();
  const dispatch = useDispatch();

  const menu_items_login = [
    {
      id: 1,
      name: "Home",
      url: "/",
      event: false,
      icon: "fa fa-home",
    },
    {
      id: 3,
      name: "Categories",
      url: "/categories",
      event: false,
      icon: "fa fa-tags",
    },
    {
      id: 4,
      name: "Recipes",
      url: "/recipes",
      event: false,
      icon: "fa fa-box",
    },
    {
      id: 5,
      name: "Social Media",
      url: "/socialmedia",
      event: false,
      icon: "fa fa-share-alt",
    },
    {
      id: 5,
      name: "Billing",
      url: "/payment",
      event: false,
      icon: "fa fa-money-bill-wave",
    },
  ];

  const menu_items_logout = [
    {
      id: 1,
      name: "Home",
      url: "/",
      event: false,
      icon: "fa fa-home",
    },
    {
      id: 2,
      name: "Login",
      url: "/login",
      event: false,
      icon: "fa fa-sign-in-alt",
    },
  ];

  return (
    <div className="bg-blue-400 flex justify-between items-center">
      <div className="text-white p-3">NuEthic</div>
      <ul className="flex">
        {isLogged
          ? menu_items_login.map((item) =>
              !item.event ? (
                <li key={item.id}>
                  <Link
                    to={item.url}
                    className="block text-white mr-5 hover:text-gray-300"
                  >
                    <i className={item.icon}></i> {item.name}
                  </Link>
                </li>
              ) : (
                <li key={item.id}>
                  <button
                    onClick={item.action}
                    className="block text-white mr-5 hover:text-gray-300 focus:outline-none"
                  >
                    <i className={item.icon}></i> {item.name}
                  </button>
                </li>
              )
            )
          : menu_items_logout.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.url}
                  className="block text-white mr-5 hover:text-gray-300"
                >
                  <i className={item.icon}></i> {item.name}
                </Link>
              </li>
            ))}
      </ul>
    </div>
  );
}

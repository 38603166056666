const initialState = {
  button: false,
  primary_color: undefined,
  secondary_color: undefined,
  layout: undefined,
  error: {
    status: false,
    message: "",
  },
};

export default function mainReducers(state = initialState, action) {
  switch (action.type) {
    case "MAIN_BUTTON": {
      return {
        ...state,
        button: true,
      };
    }
    case "MAIN_ERROR": {
      return {
        ...state,
        error: {
          status: true,
          message: action.payload,
        },
      };
    }
    case "MAIN_SUCCESS": {
      return {
        ...state,
        button: false,
        error: {
          status: false,
        },
      };
    }
    case "MAIN_ERROR_OFF": {
      return {
        ...state,
        error: {
          status: false,
        },
      };
    }
    case "MAIN_THEME_UPDATE": {
      return {
        ...state,
        button: false
      };
    }
    default:
      return state;
  }
}

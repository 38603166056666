import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs";
import { store } from "../../redux/actions/recipeActions";
import { getAll } from "../../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import MButton from "../components/Button";
import { Link, useHistory, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from '@material-ui/lab/Alert';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Insert() {
  const [data, setData] = useState(undefined);
  const dispatch = useDispatch();
  const rr_rec = useSelector((state) => state.recipeReducers);
  const rr_cat = useSelector((state) => state.categoryReducers);
  const history = useHistory();

  useEffect(() => {
    if (!rr_cat.loaded) dispatch(getAll());
  }, [dispatch, rr_cat.loaded]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(store(data));
  };

  return (
    <>
      {rr_rec.redirect && <Redirect to="/recipes" />}
      <div className="animate__animated animate__fadeIn animate__faster">
        <Breadcrumb list={["Home / Recipes / Insert"]} />
        {rr_cat.records === undefined ? (
          <Alert variant="outlined" severity="info" className="mt-10 w-11/12 mx-auto">
            Please add at least one <Link to="/categories/insert" className="font-bold text-blue-400">Category</Link> to add recipes 
          </Alert>
        ) : (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-2/5 mx-auto flex flex-col mt-10"
          >
            <div className="mb-4 w-full">
              <TextField
                error={rr_rec.errors.name ? true : false}
                helperText={rr_rec.errors.name || ""}
                name="name"
                label="Name"
                variant="outlined"
                className="w-full"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Autocomplete
                multiple
                className="w-full"
                options={rr_cat.records}
                disableCloseOnSelect
                onChange={(e, values) =>
                  setData({ ...data, categories: values })
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    error={rr_rec.errors.categories ? true : false}
                    helperText={rr_rec.errors.categories || ""}
                    {...params}
                    variant="outlined"
                    label="Categories"
                    placeholder="Categories"
                  />
                )}
              />
            </div>
            <div className="flex justify-between mt-5">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className="focus:outline-none"
              >
                <i className="fa fa-arrow-left mr-1"></i> Back
              </Button>
              <MButton
                status={rr_rec.button}
                text="Add and Edit"
                icon="fa fa-check"
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
}

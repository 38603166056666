import React, { useState, useEffect } from "react";
import { getAll, update } from "../../redux/actions/featureActions";
import { useDispatch, useSelector } from "react-redux";
import MButton from "../components/Button";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

export default function Features() {
  const [data, setData] = useState(undefined);
  const dispatch = useDispatch();
  const rr_fea = useSelector((state) => state.featureReducers);
  const { id } = useParams();

  // get recipes
  useEffect(() => {
    if (!rr_fea.loaded) dispatch(getAll(id));
  }, [dispatch, rr_fea.loaded, id]);

  useEffect(() => {
    if (rr_fea.loaded) {
      let rec = rr_fea.records;
      setData({
        servings: (rec !== undefined ) ? rec.servings : "",
        meal_type: (rec !== undefined ) ? rec.meal_type : "",
        prep_time: (rec !== undefined ) ? rec.prep_time : "",
        diet_type: (rec !== undefined ) ? rec.diet_type : "",
        cooktime: (rec !== undefined ) ? rec.cooktime : "",
        allergens: (rec !== undefined ) ? rec.allergens : "",
        protein: (rec !== undefined ) ? rec.protein : "",
        netcarbs: (rec !== undefined ) ? rec.netcarbs : "",
        calories: (rec !== undefined ) ? rec.calories : "",
        id: id
      });
    }
  }, [rr_fea.loaded, rr_fea.records, id]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(update(data));
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="recipe_title">
          <i className="fa fa-feather-alt mr-3"></i>Features
        </h1>
        {data !== undefined ? (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-10/12 mx-auto flex flex-col mt-10"
          >
            {rr_fea.success && (
              <Alert
                variant="outlined"
                severity="success"
                className="mb-5"
                onClose={() => dispatch({ type: "FEA_SUCCESS_OFF" })}
              >
                <span className="text-green-700">Modified successfully!</span>
              </Alert>
            )}
            <div className="w-full flex justify-between items-center mb-5">
              <div className="w-1/2 mr-4">
                <TextField
                  error={rr_fea.errors.servings ? true : false}
                  helperText={rr_fea.errors.servings || ""}
                  name="servings"
                  label="Servings"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.servings}
                />
              </div>
              <div className="w-1/2">
                <TextField
                  error={rr_fea.errors.meal_type ? true : false}
                  helperText={rr_fea.errors.meal_type || ""}
                  name="meal_type"
                  label="Metal Type"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.meal_type}
                />
              </div>
            </div>
            <div className="w-full flex justify-between items-center mb-5">
              <div className="w-1/2 mr-4">
                <TextField
                  error={rr_fea.errors.prep_time ? true : false}
                  helperText={rr_fea.errors.prep_time || ""}
                  name="prep_time"
                  label="Prep Time"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.prep_time}
                />
              </div>
              <div className="w-1/2">
                <TextField
                  error={rr_fea.errors.diet_type ? true : false}
                  helperText={rr_fea.errors.diet_type || ""}
                  name="diet_type"
                  label="Diet Type"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.diet_type}
                />
              </div>
            </div>
            <div className="w-full flex justify-between items-center mb-5">
              <div className="w-1/2 mr-4">
                <TextField
                  error={rr_fea.errors.cooktime ? true : false}
                  helperText={rr_fea.errors.cooktime || ""}
                  name="cooktime"
                  label="Cooktime"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.cooktime}
                />
              </div>
              <div className="w-1/2">
                <TextField
                  error={rr_fea.errors.allergens ? true : false}
                  helperText={rr_fea.errors.allergens || ""}
                  name="allergens"
                  label="Allergens"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.allergens}
                />
              </div>
            </div>
            <div className="w-full flex justify-between items-center">
              <div className="w-4/12 mr-4">
                <TextField
                  error={rr_fea.errors.protein ? true : false}
                  helperText={rr_fea.errors.protein || ""}
                  name="protein"
                  label="Protein"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.protein}
                />
              </div>
              <div className="w-4/12 mr-4">
                <TextField
                  error={rr_fea.errors.netcarbs ? true : false}
                  helperText={rr_fea.errors.netcarbs || ""}
                  name="netcarbs"
                  label="NetCarbs"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.netcarbs}
                />
              </div>
              <div className="w-4/12">
                <TextField
                  error={rr_fea.errors.calories ? true : false}
                  helperText={rr_fea.errors.calories || ""}
                  name="calories"
                  label="Calories"
                  variant="outlined"
                  className="w-full"
                  onChange={handleChange}
                  value={data.calories}
                />
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <MButton
                status={rr_fea.button}
                text="Update Features"
                icon="fa fa-check"
              />
            </div>
          </form>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
    </>
  );
}

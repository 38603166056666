import React, { useState, useEffect } from "react";
import { getAll as recGetAll, update } from "../../redux/actions/recipeActions";
import { getAll } from "../../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import MButton from "../components/Button";
import { useParams } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from "@material-ui/lab/Alert";
import CKEditor from "ckeditor4-react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Update() {
  const [data, setData] = useState(undefined);
  const [categories, setCategories] = useState(undefined);
  const dispatch = useDispatch();
  const rr_rec = useSelector((state) => state.recipeReducers);
  const rr_cat = useSelector((state) => state.categoryReducers);
  const { id } = useParams();

  // get categories
  useEffect(() => {
    if (!rr_cat.loaded) dispatch(getAll());
  }, [dispatch, rr_cat.loaded]);
  // get recipes
  useEffect(() => {
    if (!rr_rec.loaded) dispatch(recGetAll());
  }, [dispatch, rr_rec.loaded]);
  // filter recipe
  useEffect(() => {
    if (rr_rec.loaded && rr_cat.records.length > 0) {
      // eslint-disable-next-line
      const result_filter = rr_rec.records.filter((item) => item.id == id);
      setData(result_filter[0]);
      // for (let x = 0; x < result_filter[0].categories.length; x++) {
      //   let filter_categorie = rr_cat.records.filter(
      //     // eslint-disable-next-line
      //     (item) => item.id == result_filter[0].categories[x]
      //   );
      //   let result_data = {
      //     id: filter_categorie[0].id,
      //     name: filter_categorie[0].name,
      //   };
      //   arreglo.push(result_data);
      // }
      var result = [];
      setCategories(result_filter[0].categories);
    }
  }, [rr_rec.loaded, id, rr_rec.records, rr_cat.records]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data_form = {
      name: data.name,
      id: data.id,
      categories: categories,
      details: data.details,
    };
    // console.log(data_form);
    dispatch(update(data_form));
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="recipe_title">
          <i className="fa fa-file-alt mr-3"></i>Basic Information
        </h1>
        {data !== undefined ? (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-10/12 mx-auto flex flex-col mt-10"
          >
            {rr_rec.success && (
              <Alert
                variant="outlined"
                severity="success"
                className="mb-5"
                onClose={() => dispatch({ type: "REC_SUCCESS_OFF" })}
              >
                <span className="text-green-700">Modified successfully!</span>
              </Alert>
            )}
            <div className="mb-5 w-full">
              <TextField
                error={rr_rec.errors.name ? true : false}
                helperText={rr_rec.errors.name || ""}
                name="name"
                label="Name"
                variant="outlined"
                className="w-full"
                onChange={handleChange}
                value={data.name}
              />
            </div>
            <div className="w-full mb-5">
              <Autocomplete
                multiple
                className="w-full"
                options={rr_cat.records}
                value={categories}
                disableCloseOnSelect
                getOptionSelected={(option, categories) =>
                  // eslint-disable-next-line
                  option.id == categories.id
                }
                onChange={(e, values) => setCategories(values)}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    error={rr_rec.errors.categories ? true : false}
                    helperText={rr_rec.errors.categories || ""}
                    {...params}
                    variant="outlined"
                    label="Categories"
                    placeholder="Categories"
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="" className="text-gray-500">
                Description:
              </label>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={data.details}
                onChange={(event) => {
                  const dataDescription = event.editor.getData();
                  setData({
                    ...data,
                    details: dataDescription,
                  });
                }}
                config={{
                  toolbar: [
                    [
                      "Bold",
                      "Italic",
                      "Underline",
                      "Link",
                      "-",
                      "BulletedList",
                      "NumberedList",
                    ],
                  ],
                }}
              />
               
            </div>
            <div className="flex justify-center mt-5">
              <MButton
                status={rr_rec.button}
                text="Update"
                icon="fa fa-check"
              />
            </div>
          </form>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
    </>
  );
}

import api from "../../utils/api";

export const getPayment = () => async (dispatch) => {
  try {
    const response = await api.get(`/payment`)
    dispatch({
      type: 'PAY_DATA',
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
};

export const cancelPayment = () => async (dispatch) => {
  try {
    const response = await api.get(`/payment/cancel`)
    dispatch({
      type: 'PAY_CANCEL',
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
};

export const getPaymentPlans = () => async (dispatch) => {
  try {
    const response = await api.get(`/payment/plans`)
    dispatch({
      type: 'PAY_PLANS',
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
};

export const getPaymentHistory = () => async (dispatch) => {
  try {
    const response = await api.get(`/payment/history`)
    dispatch({
      type: 'PAY_HISTORY',
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
};

export const getEtoken = (cplan) => async(dispatch) => {
  try{
    const response = await api.get('/payment/token/plan/'+cplan.id)
    console.log(response)
    if(response.data.error){
      console.log(response.data.error)
      dispatch({
        type: 'PAY_ERROR',
        payload: response.data.error
      })
    } else{
      dispatch({
        type: 'PAY_ETOKEN',
        payload: response.data.token
      })
    }
  } catch(error){
    console.log(error);
    dispatch({
      type: 'PAY_ERROR',
      payload: error
    })
  }
};
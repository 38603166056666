import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getContent, updateContent } from "../../redux/actions/recipeActions";
import Alert from "@material-ui/lab/Alert";
import CKEditor from "ckeditor4-react";
import MButton from "../components/Button";

export default function Content() {
  const dispatch = useDispatch();
  const rr_rec = useSelector((state) => state.recipeReducers);
  const [data, setData] = useState(undefined);
  const { id } = useParams();

  useEffect(() => {
    // if (!rr_rec.content.loaded) dispatch(getContent(id));
    dispatch(getContent(id))
  }, [rr_rec.content.loaded, dispatch, id]);

  useEffect(() => {
    if (rr_rec.content.loaded) {
      setData(rr_rec.content.records);
    }
  }, [rr_rec.content.loaded, rr_rec.content.records]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateContent(data));
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="recipe_title">
          <i className="fa fa-shapes mr-3"></i>Content
        </h1>
        {data !== undefined ? (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-10/12 mx-auto flex flex-col mt-10"
          >
            {rr_rec.success && (
              <Alert
                variant="outlined"
                severity="success"
                className="mb-5"
                onClose={() => dispatch({ type: "REC_SUCCESS_OFF" })}
              >
                <span className="text-green-700">Modified successfully!</span>
              </Alert>
            )}
            {/* Details */}
            {/* <div className="mb-5">
              <label htmlFor="" className="text-gray-500">
                Extra Details: <span className="text-sm">Please add any extra details about your recipe here.</span>
              </label>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={data.details}
                onChange={(event) => {
                  const dataDescription = event.editor.getData();
                  setData({
                    ...data,
                    details: dataDescription,
                  });
                }}
                config={{
                  toolbar: [
                    [
                      "Undo",
                      "Redo",
                      "Bold",
                      "Italic",
                      "Underline",
                      "Link",
                      "-",
                      "BulletedList",
                      "NumberedList",
                      "-",
                      "Table",
                      "HorizontalRule",
                      "Styles",
                      "Format",
                      "Source",
                    ],
                  ],
                }}
              />
            </div> */}
            {/* Ingredients  */}
            <div className="mb-5">
              <label htmlFor="" className="text-gray-500">
                Ingredients:
              </label>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={data.elements}
                onChange={(event) => {
                  const dataDescription = event.editor.getData();
                  setData({
                    ...data,
                    elements: dataDescription,
                  });
                }}
                config={{
                  toolbar: [
                    [
                      "Undo",
                      "Redo",
                      "Bold",
                      "Italic",
                      "Underline",
                      "Link",
                      "-",
                      "BulletedList",
                      "NumberedList",
                      "-",
                      "Table",
                      "HorizontalRule",
                      "Styles",
                      "Format",
                      "Source",
                    ],
                  ],
                }}
              />
            </div>
            {/* Instructions */}
            <div>
              <label htmlFor="" className="text-gray-500">
                Instructions:
              </label>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={data.instructions}
                onChange={(event) => {
                  const dataDescription = event.editor.getData();
                  setData({
                    ...data,
                    instructions: dataDescription,
                  });
                }}
                config={{
                  toolbar: [
                    [
                      "Undo",
                      "Redo",
                      "Bold",
                      "Italic",
                      "Underline",
                      "Link",
                      "-",
                      "BulletedList",
                      "NumberedList",
                      "-",
                      "Table",
                      "HorizontalRule",
                      "Styles",
                      "Format",
                      "Source",
                    ],
                  ],
                }}
              />
            </div>
            <div className="flex justify-center mt-5">
              <MButton
                status={rr_rec.button}
                text="Update Content"
                icon="fa fa-check"
              />
            </div>
          </form>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
    </>
  );
}

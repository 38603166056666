import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Index from './Index'
import Plan from './Plan'
import Pay from './Pay'
import History from './History'
import Cancel from './Cancel'

export default function Payment() {
	let { path } = useRouteMatch();
	
	return (
		<Switch>
			<Route exact path={`${path}`} component={Index} />
			<Route path={`${path}/plans`} component={Plan} />
            <Route path={`${path}/pay/plan/:plan`} component={Pay} />
            <Route path={`${path}/pay`} component={Pay} />
            <Route path={`${path}/history`} component={History} />
            <Route path={`${path}/cancel`} component={Cancel} />
		</Switch>
	)
}
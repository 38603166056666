import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'

export default function LoginUUID() {
	const { uuid, unique, apiurl, payment, primary_color, secondary_color, layout } = useParams()
	
	useEffect( () => {
		localStorage.setItem('store_uuid', uuid)
		localStorage.setItem('unique_string', unique)
		localStorage.setItem('access_token', uuid)
		localStorage.setItem('base_url', decodeURIComponent(apiurl))
		localStorage.setItem('payment_data', payment)
		localStorage.setItem('primary_color', decodeURIComponent(primary_color))
		localStorage.setItem('secondary_color', decodeURIComponent(secondary_color))
		localStorage.setItem('layout', decodeURIComponent(layout))
		window.location.href = "/";
	},[uuid, unique]);

	return (
		<div>Loading...</div>
	);
}
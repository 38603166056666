import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import MButton from "../components/Button";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import { updateTheme } from "../../redux/actions/mainActions";

export default function Init() {
	const [data, setData] = useState({
		primary_color: localStorage.getItem('primary_color'),
		secondary_color: localStorage.getItem('secondary_color'),
		layout: localStorage.getItem('layout')
	});

	const dispatch = useDispatch();

	const handleChange = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(updateTheme(data));
	};
	
	return (
		<div className="w-10/12 p-10 bg-gray-50 mx-auto mt-10 rounded shadow-lg">
			<h1 className="text-lg font-bold border-b border-gray-300 mb-10">Instruct-A-Blog v3.0</h1>
			<p>
				Welcome to the Instruct-A-Blog admin panel. 
				To continue, please use the menu above to add categories and recipes to your store.
			</p>
			{ localStorage.getItem('primary_color') ? (
				<form 
					action=""
					onSubmit={handleSubmit}
				>
					<div className="">
						<h1 className="text-lg font-bold border-b border-gray-300 mb-10 mt-10">Store Settings</h1>
						<div className="w-full mb-5">
							<TextField
								label="Primary Color"
								variant="outlined"
								className="w-full bg-white"
								name="primary_color"
								onChange={handleChange}
								value={data.primary_color}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</div>
						<div className="w-full mb-5">
							<TextField
								label="Secondary Color"
								variant="outlined"
								className="w-full bg-white"
								name="secondary_color"
								onChange={handleChange}
								value={data.secondary_color}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</div>
						<div className="w-full mb-5">
							<InputLabel id="layout">Theme</InputLabel>
							<Select
								labelId="layout"
								id="layout"
								name="layout"
								value={data.layout}
								onChange={handleChange}
								label="Theme"
								>
								<MenuItem value={1}>Default</MenuItem>
								<MenuItem value={2}>Compact</MenuItem>
							</Select>
						</div>
						<div className="flex justify-center w-full">
							<MButton text="Save Settings" />
						</div>

					</div>
				</form>
			) : (
				<div>
					Loading...
				</div>
			) }
			
		</div>
	)
}
const initialState = {
	button: false,
	records: [],
	loaded: false,
	errors: {}
}

export default function categoryReducers(state = initialState, action) {
	switch(action.type) {
		case 'SM_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'SM_UPDATE': {
			// eslint-disable-next-line
			return {
				...state,
				button: false,
				loaded: false
			}
		}

		case 'SM_BUTTON': {
			return  {
				...state,
				button: true
			}
		}
		case 'SM_ERROR': {
			return {
				...state,
				button: false,
				errors: action.payload
			}
		}
		default: return state;
	}
}
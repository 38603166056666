import React, { useState, useEffect } from "react";
import {
  getAll as recGetAll,
  destroy,
} from "../../redux/actions/recipeActions";
import { getAllNoFilter } from "../../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import MButton from "../components/Button";
import { useParams, useHistory, Redirect } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Alert from "@material-ui/lab/Alert";
import CKEditor from "ckeditor4-react";
import MBreadcrumb from "../components/Breadcrumbs";
import Button from "@material-ui/core/Button";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Delete() {
  const [data, setData] = useState(undefined);
  const [categories, setCategories] = useState(undefined);
  const dispatch = useDispatch();
  const rr_rec = useSelector((state) => state.recipeReducers);
  const rr_cat = useSelector((state) => state.categoryReducers);
  const { id } = useParams();
  const history = useHistory();

  // get categories
  useEffect(() => {
    if (!rr_cat.loaded_all) dispatch(getAllNoFilter());
  }, [dispatch, rr_cat.loaded_all]);
  // get recipes
  useEffect(() => {
    if (!rr_rec.loaded) dispatch(recGetAll());
  }, [dispatch, rr_rec.loaded]);

  useEffect(() => {
    if (rr_rec.loaded) {
      // eslint-disable-next-line
      let result_filter = rr_rec.records.filter((item) => item.id == id);
      setData(result_filter[0]);
    }
  }, [rr_rec.loaded, rr_rec.records, id]);
  
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(destroy(data.id));
  };

  return (
    <>
      {rr_rec.redirect && <Redirect to="/recipes" />}
      <div className="animate__animated animate__fadeIn animate__faster">
        <MBreadcrumb list={["Home", "Recipes", "Delete"]} />
        {data !== undefined ? (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-10/12 mx-auto flex flex-col mt-10"
          >
            <Alert variant="outlined" severity="error" className="mb-5">
              Are you sure?. This operation cannot be reverted.
            </Alert>
            <div className="mb-5 w-full">
              <TextField
                disabled
                name="name"
                label="Name"
                variant="outlined"
                className="w-full"
                value={data.name}
              />
            </div>
            <div className="w-full mb-5">
              <Autocomplete
                disabled
                multiple
                className="w-full"
                options={rr_cat.records_all}
                value={categories}
                disableCloseOnSelect
                getOptionSelected={(option, categories) =>
                  // eslint-disable-next-line
                  option.id == categories.id
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    error={rr_rec.errors.categories ? true : false}
                    helperText={rr_rec.errors.categories || ""}
                    {...params}
                    variant="outlined"
                    label="Categories"
                    placeholder="Categories"
                  />
                )}
              />
            </div>
            <div>
              <label htmlFor="" className="text-gray-500">
                Description:
              </label>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={data.description}
                readOnly={true}
                onChange={(event) => {
                  const dataDescription = event.editor.getData();
                  setData({
                    ...data,
                    description: dataDescription,
                  });
                }}
                config={{
                  toolbar: [
                    [
                      "Bold",
                      "Italic",
                      "Underline",
                      "Link",
                      "-",
                      "BulletedList",
                      "NumberedList",
                    ],
                  ],
                }}
              />
               
            </div>
            <div className="flex justify-between mt-5 items-center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className="focus:outline-none"
              >
                <i className="fa fa-arrow-left mr-1"></i> No, take me Back
              </Button>
              <MButton
                status={rr_rec.button}
                text="Yes, delete"
                icon="fa fa-check"
              />
            </div>
          </form>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
    </>
  );
}

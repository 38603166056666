import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Show from './Show'
import Insert from './Insert'
import Props from './Props'
import Delete from './Delete'

export default function Recipes() {
	let { path } = useRouteMatch();
	
	return (
		<Switch>
			<Route exact path={`${path}`} component={Show} />
			<Route path={`${path}/insert`} component={Insert} />
			<Route path={`${path}/:id/props`} component={Props} />
			<Route path={`${path}/:id/delete`} component={Delete} />
		</Switch>
	)
}
import api from "../../utils/api";

export const getAll = (product_id) => async (dispatch) => {
   try {
      const response = await api.get(`/features/${product_id}`);
      dispatch({
         type: "FEA_RECORDS",
			payload: response.data[0]
      });
   } catch (error) {
      console.log(error);
   }
};

export const update = (data) => async (dispatch) => {
   dispatch({ type: "FEA_BUTTON" });
   try {
      const response = await api.put(`/features/${data.id}`, data);
      dispatch({
         type: "FEA_UPDATE",
         payload: response.data.data,
      });
   } catch (error) {
      // console.log("Error",error.response.data);
      dispatch({
         type: "FEA_ERROR",
         payload: error.response.data.errors,
      });
   }
};
import api from "../../utils/api";

export const getAll = () => async (dispatch) => {
  try {
    const response = await api.get("/recipes");
    dispatch({
      type: "REC_RECORDS",
      payload: response.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const store = (data) => async (dispatch) => {
  dispatch({ type: "REC_BUTTON" });
  try {
    const response = await api.post("/recipes", data);
    if (response.data.status === 409 || response.data.status === 422) {
      dispatch({
        type: "REC_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "REC_STORE",
        payload: response.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const update = (data) => async (dispatch) => {
  dispatch({ type: "REC_BUTTON" });
  try {
    const response = await api.put(`/recipes/${data.id}`, data);
    if (response.data.status === 409 || response.data.status === 422) {
      dispatch({
        type: "REC_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "REC_UPDATE",
        payload: response.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const destroy = (id) => async (dispatch) => {
	dispatch({ type: "REC_BUTTON" });
  try {
    const response = await api.delete(`/recipes/${id}`);
    if (response.data.status === 409 || response.data.status === 422  || response.data.status === 404) {
      dispatch({
        type: "REC_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "REC_DESTROY",
        payload: id,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getContent = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/recipes/${id}/content`)
    dispatch({
      type: 'REC_CONTENT',
      payload: response.data[0]
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateContent = (data) => async (dispatch) => {
  try {
    await api.put(`/recipes/${data.id}/content`, data)
    dispatch({
      type: 'REC_SUCCESS'
    })
  } catch (error) {
    console.log(error)
  }
}
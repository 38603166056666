import api from "../../utils/api";

export const getAll = () => async (dispatch) => {
  try {
    const response = await api.get("/socialmedia");
    dispatch({
      type: "SM_RECORDS",
      payload: response.data[0],
    });
  } catch (error) {
    console.log(error);
  }
};

export const update = (data) => async (dispatch) => {
  dispatch({ type: "SM_BUTTON" });
  try {
    const response = await api.post(`/socialmedia`, data);
    dispatch({
      type: "SM_UPDATE"
    });
  } catch (error) {
    dispatch({
      type: "SM_ERROR",
      payload: error.response.data.errors,
    });
  }
};

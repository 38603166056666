import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, store, destroy } from "../../redux/actions/imageActions";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MButton from "../components/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const List = (props) => {
  const { dispatch, image, toggleModal, setImageId } = props;
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAll(id));
  }, [dispatch, id]);

  const setModalProps = (image_id) => {
    setImageId(image_id);
    toggleModal();
  };

  return (
    <table className="tableDefault mt-10">
      <thead>
        <tr>
          <th>Preview</th>
          <th>Id</th>
          <th>Image</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {image.loaded ? (
          image.records.length > 0 ? (
            image.records.map((item, key) => (
              <tr key={key}>
                <td>
                  <img
                    src={item.url_thumbnail}
                    alt={`Capture ${item.id}`}
                    className="w-20"
                  />
                </td>
                <td>{item.id}</td>
                <td><a href={item.url} target="_blank"><img src={item.url} className="max-h-20" /></a></td>
                <td>
                  <button
                    className="btnDelete focus:outline-none"
                    onClick={() => setModalProps(item.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="norecords">
                No Records
              </td>
            </tr>
          )
        ) : (
          <tr>
            <td colSpan={5} className="loading">
              <i className="fa fa-spin fa-spinner fa-lg"></i>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const Delete = (props) => {
  const { dispatch, status, image_id, toggleModal, images } = props;
  const { id } = useParams();
  const [effectModal, setEffectModal] = useState("fadeInDown");
  const [effectBg, setEffectBg] = useState("fadeIn");
  const [data, setData] = useState(undefined);

  useEffect(() => {
    // eslint-disable-next-line
    const result_filter = images.records.filter((item) => item.id == image_id);
    setData(result_filter[0]);
  }, [image_id, images.records]);

  const closeModal = () => {
    setEffectModal("fadeOutUp");
    setEffectBg("fadeOut");
    setTimeout(() => {
      toggleModal();
      setEffectModal("fadeInDown");
      setEffectBg("fadeIn");
    }, 300);
  };

  const handleDelete = () => {
    dispatch(destroy(data.id, id));
  };

  return (
    <>
      {status && data !== undefined && (
        <>
          <div
            className={`fixed h-full w-full top-0 left-0 z-10 animate__animated animate__${effectBg} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.4)" }}
          ></div>
          <div
            className={`fixed w-2/5 rounded shadow-lg top-0 mt-20 z-20 bg-white animate__animated animate__${effectModal} animate__faster`}
            style={{ left: "30%" }}
          >
            <div className="flex justify-between items-center p-2 rounded border-b border-gray-200">
              <span className="text-red-500 font-bold">Delete Image</span>{" "}
              <i
                className="fa fa-times cursor-pointer fa-lg"
                onClick={closeModal}
              ></i>
            </div>
            <div className="p-3">
              Are you Sure to delete the image?{" "}
              <img src={data.url_thumbnail} alt="" className="w-full" />
            </div>
            <div className="flex justify-between p-3">
              <button
                className="bg-blue-500 text-white py-1 px-3 rounded-lg border-b-4 border-blue-700 focus:outline-none"
                onClick={closeModal}
              >
                No, Cancel
              </button>
              <button
                className="bg-red-500 text-white py-2 px-3 rounded-lg border-b-4 border-red-700 focus:outline-none"
                onClick={handleDelete}
              >
                Yes, delete
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Form = (props) => {
  const { dispatch, image } = props;
  const { id } = useParams();
  const [data, setData] = useState({
    recipe_id: id,
    type: 1,
  });

  const handleSumit = (event) => {
    event.preventDefault();
    var formData = new FormData();

    if (data.type === 1) {
      var imagefile = document.querySelector("#file");
      formData.append("image_file", imagefile.files[0]);
    } else if (data.type === 2) {
      formData.append("image_url", data.image_url);
    }
    formData.append("type", data.type);
    formData.append("recipe_id", id);
    dispatch(store(formData));
  };

  return (
    <>
      <form
        action=""
        onSubmit={handleSumit}
        className="mt-5 w-10/12 mx-auto flex items-center justify-center"
      >
        <div className="w-2/12 mr-4">
          <FormControl variant="outlined" className="w-full">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              id="demo-simple-select-outlined"
              value={data.type}
              onChange={(event) =>
                setData({ ...data, type: event.target.value })
              }
              label="Type"
            >
              <MenuItem value={1}>File</MenuItem>
              <MenuItem value={2}>URL</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="w-6/12 mr-4">
          {data.type === 2 ? (
            <TextField
              error={image.error.status || false}
              id="outlined-basic"
              placeholder="Ex. http://image.example.com/some_image.jpg"
              label="Image URL"
              variant="outlined"
              className="w-full"
              onChange={(event) =>
                setData({ ...data, image_url: event.target.value })
              }
              helperText={image.error.status && image.error.message}
            />
          ) : (
            <TextField
              error={image.error.status || false}
              label="Upload File"
              id="file"
              type="file"
              variant="outlined"
              className="w-full"
              onChange={(event) =>
                setData({ ...data, image_url: event.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
              helperText={image.error.status && image.error.message}
            />
          )}
        </div>
        <div className="w-3/12 flex justify-start items-center">
          {image.error.status && (
            <button
              type="button"
              className="m-3 focus:outline-none"
              onClick={() => dispatch({ type: "IMG_ERROR_OFF" })}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
          <MButton status={image.button} text="Add Image" icon="fa fa-check" />
        </div>
      </form>
    </>
  );
};

export default function Images() {
  const dispatch = useDispatch();
  const rr_image = useSelector((state) => state.imageReducers);
  const [deleteImage, setDeleteImage] = useState(false);
  const [imageId, setImageId] = useState(undefined);

  return (
    <>
      <h1 className="recipe_title mb-10">
        <i className="fa fa-image mr-3"></i>Images
      </h1>
      <Form dispatch={dispatch} image={rr_image} />
      <List
        image={rr_image}
        dispatch={dispatch}
        toggleModal={() => setDeleteImage(!deleteImage)}
        setImageId={(result) => setImageId(result)}
      />
      <Delete
        status={deleteImage}
        toggleModal={() => setDeleteImage(!deleteImage)}
        image_id={imageId}
        images={rr_image}
        dispatch={dispatch}
      />
    </>
  );
}

import api from "../../utils/api";

export const getAll = () => async (dispatch) => {
  try {
    const response = await api.get("/categories");
    dispatch({
      type: "CAT_RECORDS",
      payload: response.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const store = (data) => async (dispatch) => {
  dispatch({ type: "CAT_BUTTON" });
  try {
    const response = await api.post("/categories", data);
    // console.log(response.data);
    if (response.data.status === 409 || response.data.status === 422 || (response.data.errors)) {
      dispatch({
        type: "CAT_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "CAT_STORE",
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: "CAT_ERROR",
      payload: error.response.data.errors,
    });
  }
};

export const update = (data, id) => async (dispatch) => {
  dispatch({ type: "CAT_BUTTON" });
  try {
    const response = await api.post(`/categories/${id}/cat-update`, data);
    if (
      response.data.status === 409 ||
      response.data.status === 422 ||
      response.data.status === 404
    ) {
      dispatch({
        type: "CAT_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "CAT_UPDATE",
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: "CAT_ERROR",
      payload: error.response.data.errors,
    });
  }
};

export const destroy = (id) => async (dispatch) => {
  dispatch({ type: "CAT_BUTTON" });
  try {
    const response = await api.delete(`/categories/${id}`);
    if (response.data.status === 422) {
      dispatch({
        type: "CAT_ERROR",
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: "CAT_DESTROY",
        payload: id,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllNoFilter = () => async (dispatch) => {
  try {
    const response = await api.get("/categories/all/no-filter");
    dispatch({
      type: "CAT_ALL_RECORDS",
      payload: response.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const initialState = {
	loading: false,
	button: false,
	records: [],
	records_all: [],
	loaded: false,
	loaded_all: false,
	redirect: false,
	errors: {}
}

export default function categoryReducers(state = initialState, action) {
	switch(action.type) {
		case 'CAT_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'CAT_ALL_RECORDS': {
			return {
				...state,
				records_all: action.payload,
				loaded_all: true
			}
		}
		case 'CAT_STORE': {
			return {
				...state,
				records: (state.records === undefined) ? [action.payload] : state.records.concat(action.payload),
				redirect: true,
				button: false
			}
		}
		case 'CAT_UPDATE': {
			// eslint-disable-next-line
			let indice = state.records.findIndex( item => item.id == action.payload.id );
			const copy_array = [...state.records];
			copy_array[indice] = action.payload
			return {
				...state,
				records: copy_array,
				button: false,
				redirect: true
			}
		}
		case 'CAT_DESTROY': {
			// eslint-disable-next-line
			const arreglo = state.records.filter( item => item.id != action.payload );
			return {
				...state,
				records: arreglo,
				button: false,
				redirect: true
			}
		}
		case 'CAT_RELOAD': {
			return {
				...state,
				loaded: false
			}
		}
		case 'CAT_BUTTON': {
			return  {
				...state,
				button: true
			}
		}
		case 'CAT_REDIRECT_OFF': {
			return {
				...state,
				redirect: false,
				errors: {}
			}
		}
		case 'CAT_ERROR': {
			return {
				...state,
				button: false,
				errors: action.payload
			}
		}
		default: return state;
	}
}
import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

export default function MBreadcrumb(props) {
  const { list } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb" className="bg-gray-100 border-b border-gray-200 p-1">
			{list.map( (item, key) => 
					<span key={key} className="text-sm text-gray-400">{item}</span>
			)}
    </Breadcrumbs>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, store, destroy } from "../../redux/actions/videoActions";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MButton from "../components/Button";
import Alert from "@material-ui/lab/Alert";

const List = (props) => {
  const { dispatch, video, toggleModal, setVideoId } = props;
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAll(id));
  }, [dispatch, id]);

  const setModalProps = (video_id) => {
    setVideoId(video_id);
    toggleModal();
  };

  return (
    <table className="tableDefault mt-10">
      <thead>
        <tr>
          <th>Title</th>
          <th>Id</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {video.loaded ? (
          video.records.length > 0 ? (
            video.records.map((item, key) => (
              <tr key={key}>
                <td className="truncate">{item.alt_text}</td>
                <td>{item.url}</td>
                <td>
                  <button
                    className="btnDelete focus:outline-none"
                    onClick={() => setModalProps(item.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="norecords">
                No Records
              </td>
            </tr>
          )
        ) : (
          <tr>
            <td colSpan={5} className="loading">
              <i className="fa fa-spin fa-spinner fa-lg"></i>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const Delete = (props) => {
  const { dispatch, status, video_id, toggleModal, videos } = props;
  const { id } = useParams();
  const [effectModal, setEffectModal] = useState("fadeInDown");
  const [effectBg, setEffectBg] = useState("fadeIn");
  const [data, setData] = useState(undefined);

  useEffect(() => {
    // eslint-disable-next-line
    const result_filter = videos.records.filter((item) => item.id == video_id);
    setData(result_filter[0]);
  }, [video_id, videos.records]);

  const closeModal = () => {
    setEffectModal("fadeOutUp");
    setEffectBg("fadeOut");
    setTimeout(() => {
      toggleModal();
      setEffectModal("fadeInDown");
      setEffectBg("fadeIn");
    }, 300);
  };

  const handleDelete = () => {
    dispatch(destroy(data.id, id));
  };

  return (
    <>
      {status && data !== undefined && (
        <>
          <div
            className={`fixed h-full w-full top-0 left-0 z-10 animate__animated animate__${effectBg} animate__faster`}
            style={{ backgroundColor: "rgba(0,0,0,.4)" }}
          ></div>
          <div
            className={`fixed w-1/5 rounded shadow-lg top-0 mt-20 z-20 bg-white animate__animated animate__${effectModal} animate__faster`}
            style={{ left: "40%" }}
          >
            <div className="flex justify-between items-center p-2 rounded border-b border-gray-200">
              <span className="text-red-500 font-bold">Delete Video</span>{" "}
              <i
                className="fa fa-times cursor-pointer fa-lg"
                onClick={closeModal}
              ></i>
            </div>
            <div className="p-3">
              Are you Sure to delete the video id{" "}
              <span className="font-bold">{data.video_id}</span>?
            </div>
            <div className="flex justify-between p-3">
              <button
                className="bg-blue-500 text-white py-1 px-3 rounded-lg border-b-4 border-blue-700 focus:outline-none"
                onClick={closeModal}
              >
                No, Cancel
              </button>
              <button
                className="bg-red-500 text-white py-2 px-3 rounded-lg border-b-4 border-red-700 focus:outline-none"
                onClick={handleDelete}
              >
                Yes, delete
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Form = (props) => {
  const { dispatch, video } = props;
  const { id } = useParams();
  const [data, setData] = useState({
    id: id,
  });

  const handleSumit = (event) => {
    event.preventDefault();
    // console.log(data)
    dispatch(store(data));
  };
  

  return (
    <>
      <Alert
        variant="outlined"
        severity="info"
        className="w-10/12 mx-auto mt-5"
      >
        Ex.{" "}
        <span className="text-blue-300">https://www.youtube.com/watch?v=</span>
        <span className="text-blue-500 font-bold">xcJtL7QggTI</span>
      </Alert>
      <form
        action=""
        onSubmit={handleSumit}
        className="mt-5 w-10/12 mx-auto flex items-center justify-center"
      >
        <div className="w-9/12 mr-4">
          <div className="flex justify-center items-center">
            <div className={`no-wrap w-1/2 text-right pr-2 text-gray-400 border py-4 rounded border-r-0 mr-1 ${ video.error.status ? 'mb-5' : null }`}>
              https://www.youtube.com/watch?v=
            </div>
            <TextField
              error={video.error.status || false}
              id="outlined-basic"
              placeholder="Ex. xcJtL7QggTI"
              label="Video Id from Youtube"
              variant="outlined"
              className="w-1/2"
              onChange={(event) =>
                setData({ ...data, video_id: event.target.value })
              }
              helperText={video.error.status && video.error.message}
            />
          </div>
          <div className="flex justify-center items-center">
            <div className={`no-wrap w-1/2 text-right pr-2 text-gray-400 border py-4 rounded border-r-0 mr-1 ${ video.error.status ? 'mb-5' : null }`}>
              Title
            </div>
            <TextField
              error={video.error.status || false}
              id="outlined-basic"
              placeholder="Title"
              label="Video Title"
              variant="outlined"
              className="w-1/2"
              onChange={(event) =>
                setData({ ...data, video_title: event.target.value })
              }
              helperText={video.error.status && video.error.message}
            />
          </div>
        </div>
        <div className="w-3/12 flex justify-start items-center">
          {video.error.status && (
            <button
              type="button"
              className="m-3 focus:outline-none"
              onClick={() => dispatch({ type: "VID_ERROR_OFF" })}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
          <MButton status={video.button} text="Add Video" icon="fa fa-check" />
        </div>
      </form>
    </>
  );
};

export default function Videos() {
  const dispatch = useDispatch();
  const rr_video = useSelector((state) => state.videoReducers);
  const [deleteVideo, setDeleteVideo] = useState(false);
  const [videoId, setVideoId] = useState(undefined);

  return (
    <>
      <h1 className="recipe_title">
        <i className="fa fa-video mr-3"></i>Videos
      </h1>
      <Form dispatch={dispatch} video={rr_video} />
      <List
        video={rr_video}
        dispatch={dispatch}
        toggleModal={() => setDeleteVideo(!deleteVideo)}
        setVideoId={(result) => setVideoId(result)}
      />
      <Delete
        status={deleteVideo}
        toggleModal={() => setDeleteVideo(!deleteVideo)}
        video_id={videoId}
        videos={rr_video}
        dispatch={dispatch}
      />
    </>
  );
}

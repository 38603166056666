const initialState = {
	records: [],
	button: false,
	loaded: false,
	error: {
		status: false,
		message: ""
	}
}

export default function imageReducers( state = initialState, action) {
	switch(action.type) {
		case 'IMG_BUTTON': {
			return {
				...state,
				button: true
			}
		}
		case 'IMG_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'IMG_STORE': {
			return {
				...state,
				records: state.records.concat(action.payload),
				error: {
					status: false,
				},
				button: false
			}
		}
		case 'IMG_DELETE': {
			// eslint-disable-next-line
			const result = state.records.filter( item => item.id != action.payload );
			return {
				...state,
				records: result
			}
		}
		case 'IMG_ERROR': {
			return {
				...state,
				error: {
					status: true,
					message: action.payload
				},
				button: false
			}
		}
		case 'IMG_ERROR_OFF': {
			return {
				...state,
				error: {
					status: false
				}
			}
		}
		case 'IMG_RECORD_RESTART': {
			return {
				...state,
				records: [],
				loaded: false,
				button: false
			}
		}
		default: return state;
	}
}
const initialState = {
	plans: [],
	plans_loaded: false,
	payment: [],
	payment_loaded: false,
	new_plan_id: false,
	payment_error: false,
	payment_redirect: false,
	etoken: false
}

export default function paymentReducers( state = initialState, action ) {
	switch(action.type) {
		case 'PAY_PLANS': {
			return {
				...state,
				plans: action.payload,
				plans_loaded: true,
				payment_error: false
			}
		}
		case 'PAY_DATA': {
			return {
				...state,
				payment: action.payload,
				payment_loaded: true,
				payment_error: false
			}
		}
		case 'PAY_REDIRECT_OFF': {
			return {
				...state,
				payment_redirect: false
			}
		}
		case 'PAY_CANCEL': {
			return {
				...state,
				payment_loaded: false,
				payment_redirect: true
			}
		}
		case 'PAY_HISTORY': {
			return {
				...state,
				history: action.payload,
				payment_history_loaded: true,
				payment_error: false
			}
		}
		case 'PAY_ETOKEN': {
			return {
				...state,
				etoken: action.payload
			}
		}
		case 'PAY_ERROR': {
			return {
				...state,
				payment_error: action.payload
			}
		}
		default: return state;
	}
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPayment, getPaymentPlans, getEtoken } from "../../redux/actions/paymentActions";
import { base_url, store_hash } from "../../utils/constants";


export default function Plan() {
  const dispatch = useDispatch();
  const pay_red = useSelector((state) => state.paymentReducers);
  const [data, setData] = useState(undefined);
  const [plans, setPlans] = useState(undefined);
  const [cplan, setCplan] = useState(undefined);

  const { plan } = useParams();

  useEffect(() => {
    if (!pay_red.payment_loaded) {
        dispatch(getPayment());
    }
    if (!pay_red.plans_loaded) {
        dispatch(getPaymentPlans());
    }
  });

  useEffect(() => {
    if (pay_red.payment_loaded) {  
      setData(pay_red.payment);
    }
  }, [pay_red.payment_loaded, pay_red.payment]);
  useEffect(() => {
    if (pay_red.plans_loaded) {  
      setPlans(pay_red.plans);
    }
  }, [pay_red.plans_loaded, pay_red.plans]);
  useEffect(() => {
    if(cplan === undefined && plans !== undefined && data !== undefined){
        if(plan !== undefined){
            plans.forEach(function (p){
                if(p.id == plan){
                    setCplan(p);
                }
            });
        } else{
            setCplan(data.payment)
        }
    }
  }, [plans, data]);
  useEffect(() => {
    if(cplan === undefined && plans !== undefined && data !== undefined){
        if(plan !== undefined){
            plans.forEach(function (p){
                if(p.id == plan){
                    setCplan(p);
                }
            });
        } else{
            setCplan(data.payment)
        }
    }
  }, [plans, data]);

  useEffect(() => {
    if(cplan!== undefined && (pay_red.etoken === undefined || pay_red.etoken === false)){
        dispatch(getEtoken(cplan));
    }
  }, [cplan]);
  


  return (
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="flex-auto p-6 font-semibold justify-center text-center">
            Pay for Subscription
        </h1>
        {((data !== undefined) && (plans !== undefined) && (cplan !== undefined)) ? (
            <div className="text-center">
                <span className="plan-price">${cplan.price}</span> for {cplan.title}
                {(pay_red.etoken !== false && pay_red.etoken !== undefined)?(
                    <div className="subscription-container">
                        <div>
                            <span className="font-semibold text-red-600">Do not close this page until you have finished paying.</span><br />Clicking the link below will open a secure page in a new tab<br />
                            Once you have completed your payment, refresh this page to continue.
                        </div>
                        <a 
                            href={base_url+'/payment/begin/store/'+encodeURI(store_hash)+'/token/'+pay_red.etoken} 
                            className="m-auto inline-block mt-5 px-5 py-2 border border-blue-800 rounded bg-blue-600 text-white font-semibold w-auto"
                            target="_BLANK">
                            Pay with Elavon
                        </a>
                    </div>
                ) : (
                    <div>
                    {(pay_red.payment_error===false)?(
                        <div className="text-center mt-10">
                            <i className="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                    ):(
                        <div className="text-center mt-10 mx-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            {pay_red.payment_error}
                        </div>
                    )}
                    </div>
                )}
            </div>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
  );
}

// local ==================================================================
// export const client_id = 2;
// export const client_secret = 'C2skziMdEtjb0CdT54jRgfwQVwu8K8rleze3ebsU';
// export const base_url = 'http://localhost:8000';
// production =============================================================
export const client_id = 2;
export const client_secret = '7tvlt6UZ07XiioQ4LZ44zzrFnWaZffCa5tMIlRBG';
export const base_url = 'https://iabapi.nuethic.com';
// export const base_url = localStorage.getItem('base_url') ? localStorage.getItem('base_url') : 'https://instablog.ngrok.io';

export const api_url = `${base_url}/api`;
export const oauth_url = `${base_url}/oauth`;
export const grant_type = 'password';
export const access_token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : undefined;
export const payment_data = localStorage.getItem('payment_data') ? localStorage.getItem('payment_data') : undefined;
export const token_type = localStorage.getItem('token_type') ? localStorage.getItem('token_type') : undefined;
export const store_hash = localStorage.getItem('store_uuid') ? localStorage.getItem('store_uuid') : undefined;
export const unique_string = localStorage.getItem('unique_string') ? localStorage.getItem('unique_string') : undefined;

// https://iab.nuethic.com/uuid/f018c18b-ef45-483c-8db5-acf4ec9704a9/session/28967140-61bf-45e5-8487-7ff21ca95b1e
import { FormControl, FormLabel, Radio } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getPayment, getPaymentPlans } from "../../redux/actions/paymentActions";
import { payment_data } from "../../utils/constants";


export default function Plan() {
  const dispatch = useDispatch();
  const pay_red = useSelector((state) => state.paymentReducers);
  const [data, setData] = useState(undefined);
  const [plans, setPlans] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (!pay_red.payment_loaded) {
        dispatch(getPayment());
    }
    if (!pay_red.plans_loaded) {
        dispatch(getPaymentPlans());
    }
  });

  useEffect(() => {
    if (pay_red.payment_loaded) {  
      setData(pay_red.payment);
    }
  }, [pay_red.payment_loaded, pay_red.payment]);
  useEffect(() => {
    if (pay_red.plans_loaded) {  
      setPlans(pay_red.plans);
    }
  }, [pay_red.plans_loaded, pay_red.plans]);

  const handlePlanChange = (event) => {
    if(event.target.value != data.payment.id){
        pay_red.new_plan_id = event.target.value;
        pay_red.etoken = false;
        history.push('/payment/pay/plan/'+pay_red.new_plan_id)
    }
  };
  


  return (
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="flex-auto p-6 font-semibold justify-center text-center">
            Payment Plans
        </h1>
        {((data !== undefined) && (plans !== undefined) ) ? (
            <div className="flex-col">
                <div className="p-6 text-center">
                    Current Payment Plan: {data.payment.is_trial ? 'Free Trial': data.payment.title}<br />
                </div>
                {plans.map((plan, i) => {
                    return (
                        <label key={i} className="block cursor-pointer hover:bg-gray-100 w-2/5 mx-auto my-5 py-3 border rounded-md border-gray-300" htmlFor={'plan_'+plan.id}>
                            <Radio onClick={handlePlanChange} defaultChecked={plan.id==data.payment.id} type="radio" id={'plan_'+plan.id} name="plans" value={plan.id} />
                            <h2 className="inline-block font-semibold">{plan.title}</h2>
                            <div className="px-10">{plan.description}</div>
                        </label>
                    )
                })}
            </div>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
  );
}

import api from "../../utils/api";

export const getAll = (product_id) => async (dispatch) => {
  try {
    const response = await api.get(`/images/${product_id}`);
    dispatch({
      type: "IMG_RECORDS",
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const store = (data) => async (dispatch) => {
  dispatch({ type: 'IMG_BUTTON' })
  try {
    const response = await api.post(`/images`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    if (response.data.status === 409 || response.data.status === 404 || response.data.status === 422) {
			dispatch({
				type: 'IMG_ERROR',
				payload: response.data.errors.image_url
			})
    } else {
      dispatch({
        type: "IMG_STORE",
        payload: response.data,
      });
    }
  } catch (error) {
    /** 
     * javascript comment 
     * @Author: flydreame 
     * @Date: 2021-04-08 23:08:54 
     * @Desc: This errors is due to validation backend not bigcommerce. 
     */
    dispatch({
      type:"IMG_ERROR",
      payload: error.response.data.errors.image_file[0]
    })
  }
};

export const destroy = (id, product_id) => async (dispatch) => {
  try {
    await api.delete(
      `/images/${product_id}/${id}`
    );
    dispatch({
      type: "IMG_DELETE",
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

import React, { Fragment } from "react";
import Button from '@material-ui/core/Button';

export default function MButton(props) {
  const { text, status, icon } = props;

  return (
    <Fragment>
      {!status ? (
        <Button variant="contained" color="primary"  type="submit" className="focus:outline-none">
          <i className={`mr-1 ${icon}`}></i> {text}
        </Button>
      ) : (
        <Button variant="contained" color="default" disabled>
          <i className="fa fa-spin fa-spinner mr-2"></i> Loading...
        </Button>
      )}
    </Fragment>
  );
}

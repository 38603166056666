import { access_token, payment_data } from './constants'

export const verifyLogin = () => {
	let isLogged = false;
	if (access_token !== undefined) {
		isLogged = true;
	}
	return isLogged;
}

export const verifyPayment = () => {
	let hasPaid = false;
	if (payment_data !== undefined && payment_data == 1) {
		hasPaid = true;
	}
	return hasPaid;
}

export const lightenDarkenColor = (color, amount) => {
	let colorWithoutHash = color.replace("#", "")
	if (colorWithoutHash.length === 3) {
		colorWithoutHash = colorWithoutHash
		.split("")
		.map(c => `${c}${c}`)
		.join("")
	}

	const getColorChannel = substring => {
		let colorChannel = parseInt(substring, 16) + amount
		colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16)

		if (colorChannel.length < 2) {
		colorChannel = `0${colorChannel}`
		}

		return colorChannel
	}

	const colorChannelRed = getColorChannel(colorWithoutHash.substring(0, 2))
	const colorChannelGreen = getColorChannel(colorWithoutHash.substring(2, 4))
	const colorChannelBlue = getColorChannel(colorWithoutHash.substring(4, 6))

	return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`;
}
import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './views/pages/Home'
import Init from './views/pages/Init'
import Login from './views/pages/Login'
import PrivateRoutes from './routes/PrivateRoutes'
import Categories from './views/categories/Categories'
import Recipes from './views/recipes/Recipes'
import SocialMedia from './views/socialmedia/SocialMedia'
import Payment from './views/payment/Payment'
import { getStore } from './redux/actions/mainActions'
import { useDispatch } from 'react-redux'
import { verifyLogin } from "./utils/function";
import LoginUUID from './views/pages/LoginUUID'

function App() {
  const dispatch = useDispatch();
  const isLogged = verifyLogin();
  const store_hash = localStorage.getItem('store_uuid')

  useEffect( () => {
    if (isLogged && store_hash === null) dispatch(getStore());
  },[dispatch,isLogged, store_hash])

  return (
    <BrowserRouter>
      <Home>
        <Switch>
          <Route exact path="/" component={Init} />
          <Route exact path="/uuid/:uuid/session/:unique/apiurl/:apiurl/payment/:payment/primary_color/:primary_color/secondary_color/:secondary_color/layout/:layout" component={LoginUUID} />
          <PrivateRoutes path='/categories' component={Categories} />
          <PrivateRoutes path='/recipes' component={Recipes} />
          <PrivateRoutes path='/socialmedia' component={SocialMedia} />
          <Route path='/payment' component={Payment} />
          <Route path="/login" component={Login} />
        </Switch>
      </Home>
    </BrowserRouter>
  );
}

export default App;

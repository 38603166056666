const initialState = {
	loading: false,
	button: false,
	records: [],
	loaded: false,
	redirect: false,
	errors: {},
	success: false,
}

export default function categoryReducers(state = initialState, action) {
	switch(action.type) {
		case 'FEA_RECORDS': {
			return {
				...state,
				records: action.payload,
				loaded: true
			}
		}
		case 'FEA_UPDATE': {
			return {
				...state,
				button: false,
				success: true
			}
		}
		case 'FEA_BUTTON': {
			return  {
				...state,
				button: true
			}
		}
		case 'FEA_REDIRECT_OFF': {
			return {
				...state,
				redirect: false,
				errors: {}
			}
		}
		case 'FEA_ERROR': {
			return {
				...state,
				button: false,
				errors: action.payload
			}
		}
		case 'FEA_SUCCESS_OFF': {
			return {
				...state,
				success: false
			}
		}
		case 'FEA_SUCCESS': {
			return {
				...state,
				success: true
			}
		}
		default: return state;
	}
}
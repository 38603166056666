import api from "../../utils/api";
import {
  oauth_url,
  grant_type,
  client_id,
  client_secret,
} from "../../utils/constants";

export const loginAction = (data) => async (dispatch) => {
  dispatch({ type: "MAIN_BUTTON" });
  try {
    const response = await api.post(`${oauth_url}/token`, {
      grant_type: grant_type,
      client_id: client_id,
      client_secret: client_secret,
      username: data.username,
      password: data.password,
      scope: "*",
    });

    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("token_type", response.data.token_type);
    window.location.href = "/home";
  } catch (error) {
    dispatch({ type: "MAIN_ERROR", payload: "Credentials not valid." });
  }
};

export const logoutAction = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("token_type");
  localStorage.removeItem("store_uuid");
  localStorage.removeItem('unique_string')
  window.location.href = "/login";
};

export const getStore = () => async (dispatch) => {
  const request_store = await api.get("/request/store-hash");
  console.log('loading store!');
  localStorage.setItem("store_uuid", request_store.data.uuid);
  localStorage.setItem("primary_color", request_store.data.primary_color);
  localStorage.setItem("secondary_color", request_store.data.secondary_color);
  localStorage.setItem("theme_layout", request_store.data.layout);
  window.location.reload();
};

export const updateTheme = (data) => async (dispatch) => {
  dispatch({ type: "MAIN_BUTTON" });
  const result = await api.post("/theme/update",data);
  localStorage.setItem("primary_color", result.data.primary_color);
  localStorage.setItem("secondary_color", result.data.secondary_color);
  localStorage.setItem("theme_layout", result.data.layout);

  dispatch({ type: "MAIN_THEME_UPDATE" });
};

import { combineReducers } from 'redux';
import mainReducers from './mainReducers';
import categoryReducers from './categoryReducers'
import recipeReducers from './recipeReducers'
import videoReducers from './videoReducers'
import imageReducers from './imageReducers'
import crossoverReducers from './crossoverReducers'
import seoReducers from './seoReducers'
import featureReducers from './featureReducers'
import socialMediaReducers from './socialMediaReducers'
import paymentReducers from './paymentReducers'

export default combineReducers({
	mainReducers,
	categoryReducers,
	recipeReducers,
	videoReducers,
	imageReducers,
	crossoverReducers,
	seoReducers,
	featureReducers,
	socialMediaReducers,
	paymentReducers
})
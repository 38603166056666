import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getPayment, cancelPayment } from "../../redux/actions/paymentActions";
import { payment_data } from "../../utils/constants";

export default function Cancel() {
  const dispatch = useDispatch();
  const pay_red = useSelector((state) => state.paymentReducers);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (!pay_red.payment_loaded) {
        dispatch(getPayment());
    }
  });

  useEffect(() => {
    if (pay_red.payment_loaded) {  
      setData(pay_red.payment);
    }
  }, [pay_red.payment_loaded, pay_red.payment]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(cancelPayment());
  };

  return (
    <>
      {pay_red.payment_redirect && <Redirect to="/payment" />}
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="flex-auto p-6 font-semibold justify-center text-center">
            Payment Status
        </h1>
        {data !== undefined ? (
            <form
              action=""
              onSubmit={handleSubmit}
              className="w-10/12 mx-auto flex flex-col mt-10"
            >
              <div className="py-2 text-center">
                  Are you sure?
                  <div className="w-full text-center mb-2">
                      <button type="submit" className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" >Cancel Payment</button>
                  </div>
                  <div className="w-full text-center mb-2">
                      <Link className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" to="/payment">Go Back</Link>
                  </div>
              </div>
            </form>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
    </>
  );
}

import React from 'react'
import Menu from '../components/Menu'

export default function Home(props) {
	return (
		<div>
			<Menu />
			<div>
				{props.children}
			</div>
		</div>
	)
}
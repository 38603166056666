import React from "react";
import { verifyLogin } from "../utils/function";
import { verifyPayment } from "../utils/function";
import { Redirect, Route } from "react-router-dom";

export default function PrivateRoutes(props) {
  const isLogged = verifyLogin();
  const isPaid = verifyPayment();

  if (isLogged) {
    if(isPaid) return <Route {...props} />;
    else return <Redirect to="/payment" />;
  }
  else return <Redirect to="/login" />;
}

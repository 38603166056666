import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPayment } from "../../redux/actions/paymentActions";
import { payment_data } from "../../utils/constants";

export default function Index() {
  const dispatch = useDispatch();
  const pay_red = useSelector((state) => state.paymentReducers);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (!pay_red.payment_loaded) {
        dispatch(getPayment());
    }
  });

  useEffect( () => {
		dispatch({
			type: 'PAY_REDIRECT_OFF'
		})
	},[dispatch])

  useEffect(() => {
    if (pay_red.payment_loaded) {  
      setData(pay_red.payment);
    }
  }, [pay_red.payment_loaded, pay_red.payment]);


  return (
      <div className="animate__animated animate__fadeIn animate__faster">
        <h1 className="flex-auto p-6 font-semibold justify-center text-center">
            Payment Status
        </h1>
        {data !== undefined ? (
            <div className="bg-gray-200 py-2">
                <div className="w-full text-center mb-2">
                    Payment Status: {(data.store && data.store.payment_active)? (<span className="px-5 text-green-700">Active</span>) : (<span className="px-5 text-red-700">Inactive</span>) }
                </div>
                <div className="w-full text-center mb-2">
                    Next Payment Date: {data.store.next_payment_date}
                </div>
                <div className="w-full text-center mb-2">
                    Payment Plan: {data.payment.is_trial ? 'Free Trial': data.payment.title}
                    <br />
                    <Link className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" to="/payment/plans">Change Payment Plan</Link>
                </div>
                <div className="w-full text-center mb-2">
                <Link className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" to="/payment/pay">Update Payment Info</Link>
                </div>
                {/* <div className="w-full text-center mb-2">
                <Link className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" to="/payment/history">Payment History</Link>
                </div> */}
                {data.payment.is_trial? (<div></div>):(<div className="w-full text-center mb-2"><Link className="inline-block m-2 bg-white hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" to="/payment/cancel">Cancel Payment</Link></div>)}
                
            </div>
        ) : (
          <div className="text-center mt-10">
            <i className="fa fa-spin fa-spinner fa-2x"></i>
          </div>
        )}
      </div>
  );
}

import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import { NavLink, Link, useRouteMatch, Switch, Route } from 'react-router-dom'
import Update from './Update'
import Content from './Content'
import Videos from './Videos'
import Images from './Images'
import CrossOver from './CrossOver'
import Features from './Features'
import { useDispatch } from 'react-redux'

export default function Props() {
	let { url, path } = useRouteMatch();
	const dispatch = useDispatch();

	useEffect( () => {
		dispatch({ type: 'REC_CONTENT_RESTART' })
		dispatch({ type: 'VID_RECORD_RESTART' })
	},[dispatch])

	return (
		<div className="animate_animated animate_fadeIn animate_faster">
			<Breadcrumbs list={['Home','Recipes','Update']} />
			<div className="flex w-full mx-auto mt-5">
				<ul id="menu" className="w-2/12 ulMenuRecipe">
					<li><NavLink exact activeClassName="active" to={`${url}`}><i className="fa fa-file-alt"></i>Basic</NavLink></li>
					<li><NavLink activeClassName="active" to={`${url}/content`}><i className="fa fa-shapes"></i>Content</NavLink></li>
					<li><NavLink activeClassName="active" to={`${url}/videos`}><i className="fa fa-video"></i>Videos</NavLink></li>
					<li><NavLink activeClassName="active" to={`${url}/images`}><i className="fa fa-image"></i>Images</NavLink></li>
					{/* <li><NavLink activeClassName="active" to={`${url}/cross-over`}><i className="fa fa-exchange-alt"></i>Cross Over</NavLink></li> */}
					<li><NavLink activeClassName="active" to={`${url}/features`}><i className="fa fa-feather-alt"></i>Features</NavLink></li>
					<li><Link to="/recipes"><i className="fa fa-arrow-left"></i>Back</Link></li>
				</ul>
				<div id="content" className="w-10/12">
					<Switch>
						<Route exact path={`${path}`} component={Update} />
						<Route path={`${path}/content`} component={Content} />
						<Route path={`${path}/videos`} component={Videos} />
						<Route path={`${path}/images`} component={Images} />
						{/* <Route path={`${path}/cross-over`} component={CrossOver} /> */}
						<Route path={`${path}/features`} component={Features} />
					</Switch>
				</div>
			</div>
		</div>
	)
}
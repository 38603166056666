import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MButton from "../components/Button";
import { loginAction } from "../../redux/actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

export default function Login() {
  const [data, setData] = useState({
    username: '',
    password: ''
  });
  const dispatch = useDispatch();
  const rr_main = useSelector((state) => state.mainReducers);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginAction(data));
  };

  return (
    <form
      onSubmit={handleSubmit}
      action=""
      className="w-2/5 bg-gray-50 border border-gray-200 rounded-lg shadow mx-auto mt-5 p-10"
    >
      {rr_main.error.status && (
        <Alert variant="outlined" severity="error" className="mb-6 bg-red-200" onClose={() => {dispatch({ type: 'MAIN_ERROR_OFF' })}}>
          {rr_main.error.message}
        </Alert>
      )}
      <div className="w-full mb-5">
        <TextField
          label="Email"
          variant="outlined"
          className="w-full bg-white"
          name="username"
          onChange={handleChange}
          value={data.username}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div>
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          className="w-full bg-white"
          name="password"
          onChange={handleChange}
          value={data.password}
          InputLabelProps={{
            shrink: true,
          }}
        />
         
      </div>
      <div className="flex justify-center w-full">
        <MButton status={false} text="Login" icon="fa fa-lock" />
      </div>
    </form>
  );
}

import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs";
import { getAll, update } from "../../redux/actions/socialMediaActions";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MButton from "../components/Button";

export default function SocialMedia() {
  const [data, setData] = useState(undefined);
  const rSm = useSelector((state) => state.socialMediaReducers);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (!rSm.loaded) dispatch(getAll());
  }, [dispatch, rSm.loaded]);

  useEffect(() => {
    if (rSm.loaded) {
      setData(rSm.records);
    }
  }, [rSm.loaded, rSm.records]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(update(data));
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn animate__faster">
        <Breadcrumb list={["Home / Social Media / URL's"]} />

        <form
          action=""
          onSubmit={handleSubmit}
          className="w-2/5 mx-auto flex flex-col mt-10"
        >
          <div className="mb-4 w-full">
            <TextField
              name="facebook"
              label="Facebook"
              variant="outlined"
              className="w-full"
              onChange={handleChange}
              value={data && data.facebook ? data.facebook : ""}
            />
          </div>

          <div className="mb-4 w-full">
            <TextField
              name="instagram"
              label="Instagram"
              variant="outlined"
              className="w-full"
              onChange={handleChange}
              value={data && data.instagram ? data.instagram : ""}
            />
          </div>

          <div className="mb-4 w-full">
            <TextField
              name="pinteres"
              label="Pinteres"
              variant="outlined"
              className="w-full"
              onChange={handleChange}
              value={data && data.pinteres ? data.pinteres : ""}
            />
          </div>

          <div className="mb-4 w-full">
            <TextField
              name="youtube"
              label="Youtube"
              variant="outlined"
              className="w-full"
              onChange={handleChange}
              value={data && data.youtube ? data.youtube : ""}
            />
          </div>

					<div className="mb-4 w-full">
            <TextField
              name="twitter"
              label="Twitter"
              variant="outlined"
              className="w-full"
              onChange={handleChange}
              value={data && data.twitter ? data.twitter : ""}
            />
          </div>

          <div className="flex justify-center mt-5">
            <MButton status={rSm.button} text="Update" icon="fa fa-edit" />
          </div>
        </form>
      </div>
    </>
  );
}

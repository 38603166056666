import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs";
import { getAll, update } from "../../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MButton from "../components/Button";
import { useHistory, Redirect, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default function Update() {
  const [data, setData] = useState(undefined);
  const [categories, setCategories] = useState(undefined);
  const dispatch = useDispatch();
  const rr_cat = useSelector((state) => state.categoryReducers);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!rr_cat.loaded) dispatch(getAll());
  }, [dispatch, rr_cat.loaded]);

  useEffect(() => {
    if (rr_cat.loaded) {
      // eslint-disable-next-line
      let result_filter = rr_cat.records.filter((item) => item.id == id);
      setData(result_filter[0]);
    }
  }, [rr_cat.loaded, rr_cat.records, id]);

  useEffect(() => {
    if (rr_cat.loaded) {
      // eslint-disable-next-line
      let result_filter_cat = rr_cat.records.filter((item) => item.id != id);
      setCategories(result_filter_cat);
    }
  }, [rr_cat.loaded, rr_cat.records, id]);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData();
    var imagefile = document.querySelector("#file");
    formData.append("image_file", imagefile.files[0] || "");
    formData.append("name", data.name);
    formData.append("parent_id", data.parent_id);
    dispatch(update(formData, id));
  };

  return (
    <>
      {rr_cat.redirect && <Redirect to="/categories" />}
      <div className="animate__animated animate__fadeIn animate__faster">
        <Breadcrumb list={["Home / Categories / Update"]} />
        {data !== undefined && categories !== undefined && (
          <form
            action=""
            onSubmit={handleSubmit}
            className="w-2/5 mx-auto flex flex-col mt-10"
          >
            <div className="mb-4 w-full">
              <TextField
                error={rr_cat.errors.name !== undefined ? true : false}
                helperText={rr_cat.errors.name}
                name="name"
                label="Name"
                variant="outlined"
                className="w-full"
                onChange={handleChange}
                value={data.name}
              />
            </div>

            <div className="mb-4 w-full">
              <TextField
                type="file"
                error={rr_cat.errors.image_file !== undefined ? true : false}
                helperText={rr_cat.errors.image_file}
                name="image_file"
                label="Upload File"
                id="file"
                variant="outlined"
                className="w-full"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div>
              <FormControl
                error={rr_cat.errors.parent_id !== undefined ? true : false}
                variant="outlined"
                className="w-full"
              >
                <InputLabel id="category_id" onChange={handleChange}>
                  Category
                </InputLabel>
                <Select
                  labelId="category_id"
                  onChange={handleChange}
                  label="Category"
                  name="parent_id"
                  value={data.parent_id}
                >
                  <MenuItem value={0}>Root</MenuItem>
                  {categories.map((item, key) => (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{rr_cat.errors.parent_id}</FormHelperText>
              </FormControl>
            </div>
            <div className="flex justify-between mt-5">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
                className="focus:outline-none"
              >
                <i className="fa fa-arrow-left mr-1"></i> Back
              </Button>
              <MButton status={rr_cat.button} text="Update" icon="fa fa-edit" />
            </div>
          </form>
        )}
      </div>
    </>
  );
}
